import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import botLogo from "../assets/bot_logo.png"
import logoOdio from "../assets/odio-new.png";
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};
const LogoStyle = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(0),
  }));
export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.logoLight;
  const PRIMARY_MAIN = theme.palette.primary.logoMain;
  const PRIMARY_DARK = theme.palette.primary.logoDark;
  const { isCollapse } =
  useCollapseDrawer();
  const logo = (
	  <img src={isCollapse?logoOdio:botLogo} width={isCollapse?'35px' :''} />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} underline="none" color="inherit">
      {logo}
    </Link>
  );
}
