import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import ProgressBar from './ProgressBar';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  // zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>
      <ProgressBar />

      {!isDashboard && (
        <RootStyle {...other}>
          <m.div
            initial={{ rotateY: 0 }}
            animate={{ rotateY: 360 }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="50.000000pt" height="50.000000pt" viewBox="0 0 338.000000 393.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,393.000000) scale(0.100000,-0.100000)"
              fill="#293c4e" stroke="none">
              <path d="M1525 3820 c-267 -28 -549 -134 -765 -287 -121 -86 -286 -251 -372
              -373 -122 -173 -212 -381 -260 -603 -19 -87 -22 -132 -22 -312 0 -179 3 -225
              22 -311 121 -558 497 -988 1034 -1184 231 -84 553 -110 801 -64 354 66 689
              255 912 513 191 222 320 492 371 776 27 151 25 415 -4 563 -88 440 -334 803
              -708 1044 -101 65 -288 148 -413 183 -181 50 -426 73 -596 55z m404 -819 c151
              -51 276 -136 369 -253 260 -327 237 -773 -55 -1066 -170 -169 -366 -242 -616
              -229 -237 12 -436 123 -592 331 -88 118 -134 251 -142 411 -13 242 61 440 226
              605 174 174 346 243 591 236 108 -3 138 -8 219 -35z"/>
              <path d="M190 280 l0 -180 1520 0 1520 0 0 180 0 180 -1520 0 -1520 0 0 -180z"/>
              </g>
            </svg>
          </m.div>

          <Box
            component={m.div}
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 100,
              height: 100,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />

          <Box
            component={m.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />
        </RootStyle>
      )}
    </>
  );
}
